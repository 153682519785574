import React, { useContext , useEffect} from 'react'; 
import AppContext from '../../context/AppContext';
import Header from '../../components/header'; 

import Offcanvas from 'react-bootstrap/Offcanvas';
import {  useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
const BuyPage = () => {
  const navigate = useNavigate();
  const BASE_URL = window.BASE_URL;
   const myContext = useContext(AppContext);
   const { datas ,checkLogin} = myContext;
   const videoGuide = datas.web !== null &&   datas.web.videos.videoTopup.title  ?   datas.web.videos.videoTopup.title   : '';
   const videoUrl = datas.web !== null && datas.web.videos.videoTopup.video_url  ?   datas.web.videos.videoTopup.video_url   : '';
   
   const minBuy = datas.web !== null && datas.web.mintopup  ?   datas.web.mintopup : 0;
   const maxBuy = datas.web !== null && datas.web.maxtopup  ?   datas.web.maxtopup : 0;
   const gateways = datas.web !== null && datas.web.paymentGateway  ?   datas.web.paymentGateway : [];

   const [show2, setShow2] = React.useState(false);
   const [isClickedToButton, setisClickedToButton] = React.useState(false);
   const [isSubmiting, setisSubmiting] = React.useState(false);
   const [isPayment, setisPayment] = React.useState(false); 
   const [amount, setAmount] = React.useState('');
   const [upidata, setupidata] = React.useState([]);
   // method
   const [method, setMethod] = React.useState('bkash');
  
   const currency= datas.web !== null &&   datas.web.currency ?  datas.web.currency   : '$';
   
const showError = (msg) =>{
  toast.error(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    }
    );
}

const showSucess= (msg) =>{
  toast.success(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  
} 
const handleGuide = () => setShow2(!show2);
  
   
 
     
      
   const payProccess = () =>{
     
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog == null && hash == null){
      
      setisSubmiting(false);
      return;
    }

    let config = {
      headers:{
        "token":hash,
      }}; 
    axios.post(BASE_URL+ '/api/topup', {
      method: method,
      account:[],
      amount: amount
    } , config).then(({data})=> {
      //  console.log(data);
      setisSubmiting(false);
      if(data.status){ 
        setupidata(data.datas);
        //get url
        let datas = data.datas;
        if(datas.payUrl){
          window.open(datas.payUrl, '_blank');
        }
        showSucess(data.msg); 
        setisPayment(true);
      }else{
        showError(data.msg);
      }
    }).catch(e =>{
      setisSubmiting(false);
    });

   };

   React.useEffect(() => {
    document.title = "Buy Chips";

    checkLogin();
     return () => {
       
     }
   }, []);
 
  return (
    <>
    
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">
        
        
    <div className="p-3">
      <div className="mb-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center justify-content-start">
          <a  onClick={() =>{
            navigate(-1)
          }}><button className="btn btn-primary border"><span className="text-capitalize">Back</span></button></a>
        </div>
          <button type="button" className="d-flex align-items-center btn btn-outline-primary btn-md" onClick={handleGuide}><span className="text-capitalize">guide</span></button>
        </div>
      <div className="mb-3 shadow card">
        <div className="bg-light text-dark card-header">Buy Chips</div>
        <div className="card-body">
          {isPayment && (<>
         {!isClickedToButton && (<>
          <a href={upidata.payUrl}  className="btn btn-primary" >Pay With {
            (method).toUpperCase()
          }</a>
          </>)} 
 
          
          </>) }

          {!isPayment && (<>
            <div className="form-group">
            <form onSubmit={(e) =>{
              e.preventDefault();
              // showError(amount);
              setisSubmiting(true);
              payProccess();


            }}>
              <label for="amount" className="form-label w-100 text-start">Enter Amount</label>
              <div className="input-group mb-4">
                <span className="input-group-text bg-light text-dark">{currency}</span>
                <input type="number" placeholder="Amount" id="amount" min={minBuy} max={maxBuy} className="form-control" value={amount} onChange={e =>{
                  setAmount(e.target.value);
                }}  />
              
                </div>

                {/* Gateway */}
                <label for="gateway" className="form-label w-100 text-start">Select Gateway</label>
                <div className="input-group mb-4">
                  <select className="form-select" id="gateway"
                  onChange={e =>{
                    setMethod(e.target.value);
                  }}
                  required>
                    {gateways.map((gateway, index) => (
                      <option key={index} value={gateway} className="text-capitalize"
                      >{gateway}</option>
                    ))}
                  </select>
                </div>

                <div className="d-grid"> 
                  <input type="submit" className="btn btn-primary" value={isSubmiting ? "Processing.." : "Pay"} style={{
                    opacity: isSubmiting ? 0.6 : 1
                  }} disabled={isSubmiting}  />
                </div>
           </form>
          </div>

          
          </>) }
          
        </div>
      </div>
      {/* <div>
        <p className="text-capitalize text-secondary">payments secured by</p>
        <div className="d-flex justify-content-center align-items-center">
          <div className="hstack gap-2"> 
            <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/upi.svg" alt="upi logo" width="48"/>
            </div>
          </div>
      </div> */}
    </div>


    </div>

    
{show2 && (<><Offcanvas backdrop={true} className='h-75' show={show2} placement='bottom' onHide={handleGuide} >
        <Offcanvas.Header closeButton className='bg-dark ' closeVariant='white' >
          <Offcanvas.Title className="text-white fw-bold offcanvas-title h5">{videoGuide}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            
        <div style={{
            position:'relative', 
        }} ><iframe 
        width="100%" 
        height="100%" 
        src={videoUrl}
        title="YouTube video player"
         frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" 
        style={{
            height: '45vh'
        }}
        ></iframe></div>
 
 
        </Offcanvas.Body>
      </Offcanvas></>)}

      <ToastContainer/>
    </>
  )
}

export default BuyPage;