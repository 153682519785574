class WebSocketService {
    static instance = null;
  
    constructor() {
      if (!WebSocketService.instance) {
        this.socket = null;
        this.listeners = [];
        WebSocketService.instance = this;
      }
  
      return WebSocketService.instance;
    }
  
    connect(url) {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        this.socket = new WebSocket(url);
  
        this.socket.onopen = () => {
          console.log("WebSocket connected");
        };
  
        this.socket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log("Message received:", data);
  
          // Notify all listeners when a message is received
          this.listeners.forEach((callback) => callback(data));
        };
  
        this.socket.onclose = () => {
          console.log("WebSocket disconnected");
          setTimeout(() => this.connect(url), 3000); // Reconnect on disconnect
        };
  
        this.socket.onerror = (error) => {
          console.error("WebSocket error:", error);
        };
      }
    }
  
    sendMessage(data) {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify(data));
      } else {
        console.error("WebSocket is not open");
      }
    }
  
    addListener(callback) {
      this.listeners.push(callback);
    }
  
    removeListener(callback) {
      this.listeners = this.listeners.filter((listener) => listener !== callback);
    }
  }
  
  const websocketService = new WebSocketService();
  export default websocketService;
  