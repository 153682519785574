import React, { useContext ,useState} from 'react'; 
import AppContext from '../context/AppContext';
import Header from '../components/header';

import { FaInfoCircle,FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
const RegisterPage = () => {
  
  const BASE_URL = window.BASE_URL;
  // is form can be submitted
  const [isSubmit, setIsSubmit] = useState(false);
    const [userData, setUserData] = useState({
        name: '',
        phone: '',
        refer:'',
        email: '',
        password: '',
        password_confirmation: ''
    });
//   const myContext = useContext(AppContext);


const showError = (msg) =>{
    toast.error(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }
  
  const showSucess= (msg) =>{
    toast.success(msg, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    
  }
  // is valid phone
  function isValidPhone(phone) {
    const regex = /^[0-9]{10}$/; // Adjust this regex as per your phone number format
    return regex.test(phone);
}


  const sendOtp = () =>{
  
    axios.post(BASE_URL+ '/apiu/verify', {
      phone: userData.phone
    }, {
      params: {
        
      }
    }).then(({data})=> {
        
      // console.log(data);
      if(data.status){
           
        showSucess("Verification code sent to your phone!");
        localStorage.setItem('phone',userData.phone);
        navigate('/verify');

      }else{
        showError(data.msg);
      return;
      } 
    });
  }

  

const createAccount = () =>{
    if((userData.phone).length < 9 && isValidPhone(userData.phone) === false){
        showError("Check your number!");
      return;
    }
    if((userData.name).length < 5){
        showError("Check your name! Must be 5 characters or more");
    }
    if((userData.password).length < 6){
        showError("Password must be 6 characters or more!");
        return;
    }
    if(userData.password !== userData.password_confirmation){
        showError("Password do not match!");
        return;
    }


    axios.post(BASE_URL+ '/apiu/account', { 
        name: userData.name,
        phone: userData.phone,
        refer: userData.refer ,
        email: userData.email,
        password: userData.password,
        password_confirmation: userData.password_confirmation
    }, {
      params: {
         
      }
    }).then(({data})=> {
         
      if(data.status){
        setTimeout(() => {
          showSucess(data.msg);
        }, 1000);
        navigate('/login');
        
        // sendOtp();
      }else{
        showError(data.msg);
      return;
      }
   
    });
  
  
  }

 const navigate= useNavigate();
 React.useEffect(() => {
    
  document.title = "Register";
  // get localhost refer id
  if(localStorage.getItem('refer')){
    setUserData({...userData, refer: localStorage.getItem('refer')});
  }


  return () => {
    
  }
}, []);
//set all form fill then isSubmit
React.useEffect(() => {
  if(userData.name && userData.phone && userData.email && userData.password && userData.password_confirmation){
    setIsSubmit(true);
  }else{
    setIsSubmit(false);
  }
}
, [userData]);
  
    return (
    <>
    
    <Header/>
     
    <div className=" col-12 mx-auto p-3 g-0">
        <div className="card">
            <div className="bg-light text-dark text-capitalize card-header">Register</div>
            <div className="card-body">
                <form className="mb-3" onSubmit={(e) =>{
                    e.preventDefault(); 
                    // console.log(userData);
                    createAccount();
                }}>
                    <div className="vstack gap-2">
                        <div className="d-flex flex-column align-items-start">
                            <label className="text-capitalize form-label">Full Name</label>
                            <input required="" type="text" className="form-control" onChange={e =>{
                                setUserData({...userData, name: e.target.value})
                            }} />
                        </div>
                        {/* email */}
                        <div className="d-flex flex-column align-items-start">
                            <label className="text-capitalize form-label">Email</label>
                            <input required="" type="email" className="form-control" onChange={e =>{
                                setUserData({...userData, email: e.target.value})
                            }} />
                        </div>
                        {/* phone */}
                        <div className="d-flex flex-column align-items-start">
                                <label className="text-capitalize form-label">Phone number</label>
                                <input required="" type="tel" className="form-control"  onChange={e =>{
                                setUserData({...userData, phone: e.target.value})
                            }} />
                        </div>
                        {/* password */}
                        <div className="d-flex flex-column align-items-start">
                            <label className="text-capitalize form-label">password</label>
                            <input required="" type="password" className="form-control"  onChange={e =>{
                                setUserData({...userData, password: e.target.value})
                            }} />
                        </div>
                        {/* confirm password */}
                        <div className="d-flex flex-column align-items-start">
                            <label className="text-capitalize form-label">confirm password</label>
                            <input required="" type="password" className="form-control"  onChange={e =>{
                                setUserData({...userData, password_confirmation: e.target.value})
                            }} />
                        </div>
                        {/* refer code */}
                        <div className="d-flex flex-column align-items-start">
                            <label className="text-capitalize form-label">refer code (optional)</label>
                            <input type="text"
                            value={userData.refer}
                            readOnly
                            className="form-control"  onChange={e =>{
                                setUserData({...userData, refer: e.target.value})
                            }} />
                        </div>
                    <div>
                    <p stylexx="font-size: 0.8rem; text-align: start;">By Continuing, you agree to our <Link to="/terms">Legal Terms</Link> and you are 18 years or older.</p></div>
                    <button type="submit" disabled={!isSubmit} className="text-capitalize btn btn-primary">Register</button>
                    </div>
                </form>
                <p stylexx="font-size: 0.8rem;">Already have an account? <Link to="/login">Login</Link>
            </p>
            </div>
            </div>
            </div>

            <ToastContainer/>
    </>
  )
}

export default RegisterPage;