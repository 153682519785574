import logo from './logo.svg';
import './App.css';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Routes, Route, Router,BrowserRouter } from "react-router-dom"
import HomePage from './pages/home';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import SupportPage from './pages/support';
import TermsPage from './pages/terms';
import VerifyPage from './pages/verify';
import PlayingPage from './pages/loggedIn/playing';
import {reducer, initialState} from './context/reducer'
import AppContext from './context/AppContext';
import NotFoundPage from './pages/notfound';
import ProfilePage from './pages/loggedIn/profile';
import WalletPage from './pages/loggedIn/wallet';
import HistoryPage from './pages/loggedIn/history'; 
import BuyPage from './pages/loggedIn/buy'; 
import WithdrawPage from './pages/loggedIn/withdraw'; 
import KycPage from './pages/loggedIn/kyc'; 
import ChallengesPage from './pages/loggedIn/challenges'; 
import ReferPage from './pages/loggedIn/refer';
import axios from 'axios'; 
import LitePage from './pages/loggedIn/lite'; 
import websocketService from "./context/socket";

function App() {
  const BASE_URL = window.BASE_URL;
  const logoUrl = window.logoUrl;
  const [stdatas, dispatch] = useReducer(reducer, initialState);
  const isLogged = useCallback(() => {
    
    if(stdatas.login == null) return false;
    if(typeof stdatas.login.isLogged != 'boolean') return false;
    return stdatas.login.isLogged;
  }, [
    stdatas
  ]);

  const checkLoginStatus = () =>{
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog == null && hash == null){
      // console.log(isLog,hash);
      return;
    }

    let config = {
      headers:{
        "token":hash,
      }}; 
    axios.get(BASE_URL+ '/api/me', config).then(({data})=> {
      
      // console.log(data);
      if(data.status){
        dispatch({ type: "login", login_data: { isLogged: true, ...data} });
      }else{ 
      }
    });
  };

  const checkLogin = () =>{
    let isLog= localStorage.getItem('isLogged');
    let hash= localStorage.getItem('hash');
    if(isLog !== null && hash !== null){
        
      checkLoginStatus();
    }
  }
  const webStatus = () =>{

    axios.get(BASE_URL+ '/apiu/status').then(({data})=> {
       
      if(data.status){

        dispatch({ type: "set_web", web: data });
        setIsReady(true);
      }else{
        document.location.reload();
      }
    });
  };

  const getLoad = () =>{
    webStatus();
  };

  useEffect(() => {
    // first initial load 
    checkLogin();
    getLoad();
    return () => {
      
    }
  }, []);
  const [isReady, setIsReady] = useState(false);
  // on set web
  useEffect(() => {
    if(stdatas.web !== null && stdatas.login !== null){
      // console.log(stdatas.login.data.session);
      if(stdatas.login.data.session !== null){
      let url = stdatas.web.socket + '?token=' + stdatas.login.data.session;
      websocketService.connect(url);
      }
    } 

  }, [stdatas.web, stdatas.login]);
  
  const userSettings = {
    datas: stdatas,
    dispatch,
    isLogged,
    checkLogin,
    webStatus
  };
  if(!isReady){
    return <>
    <div
    
    style={{

      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }}


>
      <img src={logoUrl} height={80}/>
      <p style={{
        fontSize:'large',
        fontWeight:'600'
      }}>Loading...</p>


    </div>
    </>
  }
  return (
    <>
      <AppContext.Provider value={userSettings}>
       
    <BrowserRouter>
      <Routes> 
        
          <Route path='/' element={<HomePage />} /> 
          {isLogged() ? (<>
          <Route path='/play/:id' element={<PlayingPage />} /> 
            <Route path='/profile' element={<ProfilePage />} />  
            <Route path='/wallet' element={<WalletPage />} />  
            <Route path='/history' element={<HistoryPage />} />  
            <Route path='/buy' element={<BuyPage />} />  
            <Route path='/sell' element={<WithdrawPage />} />  
            <Route path='/withdraw' element={<WithdrawPage />} />  
            <Route path='/refer' element={<ReferPage />} />  
            <Route path='/kyc' element={<KycPage />} />  
            <Route path='/challenges' element={<ChallengesPage />} />  
            <Route path='/lite' element={<LitePage />} />
          </>) 
          
          : (<>
          <Route path='/login' element={<LoginPage />} /> 
          <Route path='/register' element={<RegisterPage />} /> 
          <Route path='/verify' element={<VerifyPage />} /> 
          </>) }
          <Route path='/support' element={<SupportPage />} /> 
          <Route path='/terms' element={<TermsPage />} /> 
        {/* </Route> */}
        {/* not found */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>

      </AppContext.Provider>
    </>);
}

export default App;
