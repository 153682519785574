import React, { useContext, useState } from 'react'; 
import AppContext from '../context/AppContext';
import Header from '../components/header';

import { FaInfoCircle,FaLock,FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const LoginPage = () => {
  
  const BASE_URL = window.BASE_URL; 
  
  const myContext = React.useContext(AppContext);
  const { datas, dispatch ,isLogged,checkLogin} = myContext;
  

const showError = (msg) =>{
  toast.error(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    });
}

const showSucess= (msg) =>{
  toast.success(msg, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
  
}


// is email or phone
function isValidPhoneOrEmail(phone) {
  const regex = /^[0-9]{10}$/; // Adjust this regex as per your phone number format
  if(phone.match(regex)){
    return true;
  }
  // email
  const email = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if(phone.match(email)){
    return true;
  }
  return false;
}
const [number, setNumber] = useState('');
const [password, setPassword] = useState('');
const navigate = useNavigate();
const login = () =>{
  if(isValidPhoneOrEmail(number) == false){  
    showError("Check Your Phone/Email!");
    return;
  }
  if(password.length < 6){
    showError("Password must be 6 characters or more!");
    return
  }

  axios.post(BASE_URL+ '/apiu/login', {
    phone: number,
    password: password
  }, {
    params: {
      
    }
  }).then(({data})=> {
      
    // console.log(data);
    if(data.status){
      // sendOtp();
      
      localStorage.setItem('isLogged', true);
      localStorage.setItem('hash', data.data.session);
      setTimeout(() => {
         showSucess("Successfully Logged In!");
      }, 1000);
      dispatch({ type: "login", login_data: { isLogged: true, ...data} });
      
      checkLogin();
      navigate('/');

    }else{
      showError(data.msg);
    return;
    }
 
  });


}
 
React.useEffect(() => {
  
  document.title = "Login";
  let number = localStorage.getItem('phone');
  if(number != '' || number != null ){
    setNumber(number);
  }


  return () => {
    
  }
}, []);

  return (
    <>
    <Header/>
     

    <div className=" col-12 mx-auto p-3 g-0">
      <div className="card">
        <div className="bg-light text-dark card-header">Login</div>
        <div className="card-body">
          <form onSubmit={e =>{
            e.preventDefault();
            login();
          }}>
            
          {/* <label for="phone" className="w-100 text-start form-label">Mobile/Email</label> */}
          <div className="input-group">
            <span className="input-group-text bg-light text-dark">
             <FaPhoneAlt />
              </span>
            <input value={number} required onChange={(e) => setNumber(e.target.value)} type="text" placeholder='Enter Email/Phone' name="phone" id="phone" className="form-control" aria-describedby="phone"   />
          </div>
          <div className="input-group mt-2">
            <span className="input-group-text bg-light text-dark">
             <FaLock />
              </span>
            <input 
             value={password}
             required onChange={(e) => setPassword(e.target.value)} type="password" placeholder='Enter Your Password' name="password" id="password" className="form-control"   />
          </div>
            
            <div className="d-grid py-3">
              <p stylecc="font-size: 0.8rem;">By Continuing, you agree to our <Link to="/terms">Legal Terms</Link> and you are 18 years or older.</p>
              <button className="btn btn-primary text-uppercase" type='submit' onClick={() =>{
                // login();
              }}>Login</button>
              </div>
              
          </form>
              <div>
                <p stylecc="font-size: 0.9rem;">
                  Don't have an account? <Link to="/register">Register</Link>
                  </p></div>
              </div></div></div>

              <ToastContainer/>
    </>
  )
}

export default LoginPage;