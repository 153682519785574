import React, { useContext } from 'react'; 
import AppContext from '../context/AppContext';
import { Link, NavLink } from 'react-router-dom';
import Header from '../components/header';

import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaInfoCircle,FaExclamation ,FaHeadset} from "react-icons/fa";
const HomePage = () => {

  const myContext = useContext(AppContext);
  const [show2, setShow2] = React.useState(false);
 
  const handleGuide = () => setShow2(!show2);
  const { datas, dispatch ,isLogged} = myContext; 
  const videoGuide = datas.web !== null &&   datas.web.videos.videoHow.title  ?   datas.web.videos.videoHow.title   : '';
  const videoUrl = datas.web !== null && datas.web.videos.videoHow.video_url  ?   datas.web.videos.videoHow.video_url   : '';
  React.useEffect(() => {
    
    document.title = "Ludo Play";
  
    return () => {
      
    }
  }, []);
  

  return (
    <>
    <Header/>

    <div className=" col-12 mx-auto p-3 g-0">
        <div>
            
        {isLogged() && (<>

            {datas.web !== null && datas.web.isAlertActive && datas.web.alert !=='' && (<>
                <div role="alert"
             class="fade d-flex align-items-center justify-content-between alert alert-warning show"
              stylexx="font-size: 0.9rem; text-align: left;"><span><b>
                {datas.web.alert}</b></span></div>
            </>)}
           
            {datas.login !== null &&  datas.web.kyc && !datas.login.data.isVerified  && (<>
            <div role="alert" class="fade d-flex align-items-center justify-content-between alert alert-danger show">
                <span><b>KYC Pending </b></span>
                <Link to="/kyc">
                    <button class="btn btn-danger btn-sm text-capitalize">complete here</button>
                </Link>
            </div>
            </>)}

            <div class="d-flex align-items-center justify-content-between mt-3 mb-4">
                <h1 class="text-capitalize text-start">games</h1>
                <button type="button" class="d-flex align-items-center btn btn-outline-primary btn-md" onClick={() =>{
                    handleGuide();
                }}>
                    <FaInfoCircle/> <span class="text-capitalize"> guide</span></button></div>


            <div class="p-0 container-fluid">
                <div class="mb-3 gx-3 row">
                    <div class="col" style={{position:'relative'}}>
                        <Link class="text-decoration-none text-black" to="/lite">
                            {/* <div className='priceBar'>
                                <span>$50 - $500</span>
                            </div> */}
                            <picture>
                                <source media="(min-width:1024px)" 
                                 srcset={`${process.env.PUBLIC_URL}/imgi/ludo_classic_lite_desktop.webp`}  />
                                    <source media="(min-width:768px)"
                                    
                                 srcset={`${process.env.PUBLIC_URL}/imgi/ludo_classic_lite_tablet.webp`}  />
                                        <img
                                        style={{
                                            width:'100%',
                                            cursor:'pointer'
                                        }}
                                        src={`${process.env.PUBLIC_URL}/imgi/ludo_classic_lite_mobile.webp`}   alt="ludo classic" class="rounded-3"  />
                            </picture>
                        </Link>
                        </div>
                         
                     
                    <div class="col" style={{position:'relative'}}>
                        <Link class="text-decoration-none text-black" to="/challenges">
                            
                        {/* <div className='priceBar'>
    <span>$50 - $500</span>
</div> */}
                            <picture>
                                <source media="(min-width:1024px)" 
                                 srcset={`${process.env.PUBLIC_URL}/imgi/lcrDesktopNew.webp`}  />
                                    <source media="(min-width:768px)"
                                    
                                 srcset={`${process.env.PUBLIC_URL}/imgi/lcrTabletNew.webp`}  />
                                        <img
                                        style={{
                                            width:'100%',
                                            cursor:'pointer'
                                        }}
                                        src={`${process.env.PUBLIC_URL}/imgi/lcrMobileNew.webp`}   alt="ludo classic" class="rounded-3"  />
                            </picture>
                        </Link>
                        </div>
                         </div></div>

            
        </>)}

            
            {!isLogged() && (<>
        
        <div>
        
        <img src={`${process.env.PUBLIC_URL}/imgi/landingNew.webp`} alt="dice" className="w-100"/>
        </div>
        <div className="text-center text-muted" stylecx="margin-top: 1em; padding-bottom: 90px;">
            <i>This Game involves an element of financial risk and may be addictive. Please Play responsibly and at your own risk.</i></div>

                <div className="position-fixed d-flex align-items-center justify-content-center hstack gap-2" 
            style={{
                zIndex:10,
                bottom:10,
                left:0,
                right:0, 
                padding:10
            }}>
                <div className="d-grid flex-grow-1"> 
                    <Link to={'/login'} className="btn btn-dark btn-lg fw-semibold py-3" href="#/login">Play Now</Link></div>
                    <Link to={'/support'}className="bg-primary shadow-lg rounded-circle d-flex align-items-center justify-content-center text-white" href="#/support" style={{
                        height:60,
                        width:60,
                    }}  >
           <FaHeadset/>
        </Link></div>
        </>)}
        

        </div></div>

        
{show2 && (<><Offcanvas backdrop={true} className='h-75' show={show2} placement='bottom' onHide={handleGuide} >
        <Offcanvas.Header closeButton className='bg-dark ' closeVariant='white' >
          <Offcanvas.Title className="text-white fw-bold offcanvas-title h5">{videoGuide}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
            
        <div style={{
            position:'relative', 
        }} ><iframe 
        width="100%" 
        height="100%" 
        src={videoUrl}
        title="YouTube video player"
         frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" 
        style={{
            height: '45vh'
        }}
        ></iframe></div>
 
 
        </Offcanvas.Body>
      </Offcanvas></>)}


    </>
  )
}

export default HomePage;